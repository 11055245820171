.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  @apply text-base inline-block text-gray-900 dark:text-gray-100 rounded bg-white dark:bg-primary-900 shadow-lg;
}

.emoji-mart .emoji-mart-emoji {
  @apply p-1.5 align-middle;
}

.emoji-mart-bar {
  @apply border-0 border-solid border-gray-200 dark:border-gray-800;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  @apply flex flex-row justify-between px-1.5;
}

.emoji-mart-anchor {
  @apply relative block flex-auto text-gray-700 dark:text-gray-600 text-center overflow-hidden transition-colors py-3 px-1;
}

.emoji-mart-anchor:focus { outline: 0; }

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  @apply text-gray-600 dark:text-gray-300;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  @apply bottom-0;
}

.emoji-mart-anchor-bar {
  @apply absolute -bottom-0.5 left-0 w-11/12 h-0.5 bg-primary-600;
}

.emoji-mart-anchors i {
  @apply inline-block w-full;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentcolor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 270px;
  padding: 0 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  @apply relative mt-1.5 p-2.5 pr-12 bg-white dark:bg-primary-900;
}

.emoji-mart-search input {
  @apply text-sm pr-9 block w-full border-gray-300 dark:bg-transparent dark:border-gray-800 rounded-full focus:ring-primary-500 focus:border-primary-500;

  &::-moz-focus-inner {
    border: 0;
  }

  &::-webkit-search-cancel-button {
    @apply hidden;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  appearance: none;
}

.emoji-mart-search-icon {
  @apply absolute z-10 border-0;
  top: 20px;
  right: 56px;
  padding: 2px 5px 1px;
}

.emoji-mart-search-icon svg {
  @apply fill-gray-700 dark:fill-gray-600;
}

.emoji-mart-search-icon:hover svg {
  @apply stroke-gray-800;
}

.emoji-mart-category .emoji-mart-emoji span {
  @apply relative text-center;
  z-index: 1;
}

.emoji-mart-category .emoji-mart-emoji:hover::before {
  @apply bg-gray-50 dark:bg-primary-800;
  z-index: 0;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  @apply bg-white dark:bg-primary-900;
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'Apple Color Emoji', 'Twemoji Mozilla', 'Noto Color Emoji', 'Android Emoji', sans-serif;
}

.emoji-mart-no-results {
  @apply text-sm text-center text-gray-600 dark:text-gray-300;
  padding-top: 70px;
}

.emoji-mart-no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover::before {
  content: none;
}

.emoji-mart-preview {
  @apply hidden;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.emoji-picker-dropdown__menu {
  @apply rounded-lg absolute mt-1.5;
  transform: translateX(calc(-1 * env(safe-area-inset-right))); /* iOS PWA */
  z-index: 20000;

  .emoji-mart-scroll {
    transition: opacity 200ms ease;
  }

  &.selecting .emoji-mart-scroll {
    opacity: 0.5;
  }
}

.emoji-picker-dropdown__modifiers {
  position: absolute;
  top: 65px;
  right: 14px;
  cursor: pointer;
}

.emoji-picker-dropdown__modifiers__menu {
  @apply absolute bg-white dark:bg-primary-900 rounded-3xl shadow overflow-hidden;
  z-index: 4;
  top: -4px;
  left: -8px;

  button {
    @apply block cursor-pointer border-0 px-2 py-1 bg-transparent;

    &:hover,
    &:focus,
    &:active {
      @apply bg-gray-300 dark:bg-primary-600;
    }
  }

  .emoji-mart-emoji {
    height: 22px;
  }
}

.font-icon-picker {
  .emoji-mart-search {
    // Search doesn't work. Hide it for now.
    display: none;
    padding: 10px !important;
  }

  .emoji-mart-category-label > span {
    padding: 9px 6px 5px;
  }

  .emoji-mart-scroll {
    border-radius: 4px;
  }

  .emoji-mart-search-icon {
    right: 18px;
  }

  .emoji-mart-bar {
    display: none;
  }

  .fa {
    font-size: 18px;
    width: 22px;
    height: 22px;
    text-align: center;
  }

  .fa-hack {
    margin: 0 auto;
  }
}
